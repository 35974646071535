import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { PayoutsService } from 'src/app/services/payouts/payouts.service';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './payouts-transaction-details.component.html',
  styleUrls: ['./payouts-transaction-details.component.scss'],
})
export class PayoutsTransactionDetailsComponent {
  public transactionId: string = '';
  public transactionInfo: any = {
    general_info: {},
    payment_info: { fx_updated_date: null },
    customer_info: {},
    other_info: {},
    transaction_history: {},
  };
  public generalIfo = {
    external_id: '',
    merchant_id: '',
    merchant_name: '',
    project_id: '',
    transaction_id: '',
    transaction_status: '',
  };
  public generalInfoList: any = [
    { label: 'Transaction Status', value: 'transaction_status' },
    { label: 'Additional Info', value: 'additional_info' },
    { label: 'Transaction ID', value: 'transaction_id' },
    { label: 'External ID', value: 'external_id' },
    { label: 'Contract ID', value: 'project_id' },
  ];
  public beneficiaryInfoList: any = [
    { label: 'Tax ID', value: 'tax_id' },
    { label: 'Pix Key', value: 'pix_key' },
  ];

  public payoutInfoList: any = [
    { label: 'Order Method', value: 'order_method' },
    { label: 'Order Method Country', value: 'order_method_country' },
    { label: 'End to End ID', value: 'end_to_end_id' },
    { label: 'Error Code', value: 'error_code' },
    { label: 'Description', value: 'gateway_status' },
  ];

  public payoutStatus: Array<string> = [];
  public selectedStatus: string = '';
  public loading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private payoutsService: PayoutsService
  ) {}

  ngOnInit(): void {
    this.transactionId = this.activatedRoute.snapshot.queryParams['id'];
    this.payoutsService
      .getPayoutsDetails(this.transactionId)
      .subscribe((response) => {
        this.transactionInfo = response;
      });
    this.payoutsService.getPayoutStatuses().subscribe(({ content }) => {
      this.payoutStatus = content;
    });
  }

  public downloadReceipt() {
    this.payoutsService.downloadReceipt(this.transactionId).subscribe(
      (blob) => {
        const fileName = `receipt-${this.transactionId}.pdf`;
        saveAs(blob, fileName);
      },
      (error) => {
        console.error('Erro ao baixar o arquivo', error);
      }
    );
  }

  public backToPayouts() {
    this.router.navigate(['/payouts/reports/transaction-reports']);
  }
}
