import {
  Component,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
} from '@angular/core';
import {
  FirewallRule,
  FirewallRulesService,
} from '../../../services/firewall-rules/firewall-rules.service';
import { ContractsService } from '../../../services/projects/contracts.service';
import { BlockMethods, PaymentMethods } from '../../../utils/enum';
import { finalize } from 'rxjs';
import { Contract } from 'src/app/models/contract.model';

@Component({
  selector: 'app-unblock-modal',
  templateUrl: './unblock-modal.component.html',
  styleUrls: ['./unblock-modal.component.scss'],
})
export class UnblockModalComponent {
  @Output() closeModalRequest = new EventEmitter<void>();
  @Input() blockedContract: FirewallRule = {
    id: 'a',
    contract_id: '',
    rule_type: '',
    rule: '',
    description: '',
    payment_methods: [],
  };
  public options: { label: string; value: string }[] = [];
  public contractList: Contract[] = [];
  public selectedContract: Contract[] = [];
  public creditCardSelected = false;
  public pixSelected = false;
  public boletoSelected = false;
  public rule = '';
  public ruleType = '';
  public ruleDescription = '';
  public paymentMethodsList: string[] = [
    'BITCOIN',
    'BOLETO',
    'CREDITCARD',
    'DEBITCARD',
    'NOT_DEFINED',
    'OXXO',
    'PIX',
    'RIPPLE',
    'SPEI',
    'TEDDOC',
  ];
  public selectedPaymentMethods: { [key: string]: boolean } = {};
  public unblockReason: string = '';

  constructor(
    private firewallRulesService: FirewallRulesService,
    private contractsService: ContractsService
  ) {}

  ngOnInit(): void {
    this.getBlockMethods();
    this.contractsService.getContracts().subscribe(({ contracts }) => {
      this.contractList = contracts;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['blockedContract'] && changes['blockedContract'].currentValue) {
      this.blockedContract = changes['blockedContract'].currentValue;
    }
  }

  public close() {
    this.closeModalRequest.emit();
  }

  public unblockContract() {
    this.firewallRulesService
      .postUnblock(this.blockedContract.id)
      .pipe(
        finalize(() => {
          this.close();
          location.reload();
        })
      )
      .subscribe();
  }

  public getFirstHalfOfPaymentMethods() {
    const halfIndex = Math.ceil(this.paymentMethodsList.length / 2);
    return this.paymentMethodsList.slice(0, halfIndex);
  }

  public getSecondHalfOfPaymentMethods() {
    const halfIndex = Math.ceil(this.paymentMethodsList.length / 2);
    return this.paymentMethodsList.slice(halfIndex);
  }

  public getPaymentMethodName(method: string): string {
    return PaymentMethods[method as keyof typeof PaymentMethods];
  }

  private getBlockMethods() {
    this.firewallRulesService.getBlockMethods().subscribe((response) => {
      response.forEach((element: string) => {
        this.options.push({
          value: element,
          label: BlockMethods[element as keyof typeof BlockMethods],
        });
      });
    });
  }
}
