import { Component, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuItem } from '../models/menu-item.model';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() item!: MenuItem;
  @Input() isSidebarMinimized = false;
  @Input() expandedMenus!: Set<string>;
  @Input() selectedMenu!: MenuItem | null;
  @Input() level: number = 1;

  @Output() toggleMenu = new EventEmitter<MenuItem>();
  @Output() selectMenuItem = new EventEmitter<MenuItem>();

  public theme = environment.theme;
  public isHovering: boolean = false;

  public isMenuExpanded(): boolean {
    return this.expandedMenus.has(this.item.label);
  }

  public isMenuSelected(): boolean {
    return this.isItemOrDescendantSelected(this.item);
  }

  private isItemOrDescendantSelected(item: MenuItem): boolean {
    if (this.selectedMenu) {
      if (item.path === this.selectedMenu.path) {
        return true;
      }
      if (item.children) {
        for (const child of item.children) {
          if (this.isItemOrDescendantSelected(child)) {
            return true;
          }
        }
      }
    }
    return false;
  }
  

  public onMenuItemClick(): void {
    this.selectMenuItem.emit(this.item);
  }

  public onToggleMenu(): void {
    if (this.isSidebarMinimized) {
      return;
    }
    this.toggleMenu.emit(this.item);
  }

  public onMouseEnter(): void {
    this.isHovering = true;
  }

  public onMouseLeave(): void {
    this.isHovering = false;
  }
}
