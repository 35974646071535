<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field appearance="outline">
      <mat-label>Date Interval</mat-label>
      <mat-date-range-input [rangePicker]="requestDate">
        <input
          matStartDate
          placeholder="Start Request date"
          [(ngModel)]="startDateValue"
        />
        <input
          matEndDate
          placeholder="End Request date"
          [(ngModel)]="endDateValue"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="requestDate"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #requestDate></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Select Status</mat-label>
      <mat-select [(value)]="selectedStatus">
        <mat-option *ngFor="let status of statusList" [value]="status">{{
          status | splitUnderscore
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Search:</mat-label>
      <input [(ngModel)]="search" #searchInput matInput />
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="filterTable()">
      Filter
    </button>
  </app-fieldset>

  <app-fieldset tittle="Downloads">
    <app-download-csv-button
      [csvFilter]="filterParamsCsv"
      buttonText="Transactions"
    ></app-download-csv-button>
    <!-- <button mat-flat-button color="primary">
  <mat-icon class="material-symbols-outlined">download</mat-icon>KYC
</button> -->
  </app-fieldset>

  <ngx-simplebar [options]="{ autoHide: false }" style="overflow-y: hidden">
    <div class="row">
      <app-card [cardTitle]="''" style="margin-right: 16px !important">
        <div>
          <table
            mat-table
            [dataSource]="dataSource"
            class="transactions-table \"
            cdk-virtual-scroll
            [loader]="isLoading"
          >
            <ng-container matColumnDef="paymentId">
              <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
              <td mat-cell *matCellDef="let element">
                <a
                  [routerLink]="['/payments/transaction-report/detail']"
                  [queryParams]="{ id: element.paymentId }"
                >
                  {{ element.paymentId }}</a
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="paymentStatus">
              <th mat-header-cell *matHeaderCellDef>Transaction<br />Status</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">
                  {{ element.transactionStatus }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="reference">
              <th mat-header-cell *matHeaderCellDef>External ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.reference }}
              </td>
            </ng-container>

            <ng-container matColumnDef="contractId">
              <th mat-header-cell *matHeaderCellDef>Contract ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.contractId }}
              </td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef>Purchase Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdAt | date : "yyyy-MM-dd:HH:mm:ss" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="confirmationDate">
              <th mat-header-cell *matHeaderCellDef>Confirmation Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.confirmationDate | date : "yyyy-MM-dd:HH:mm:ss" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="ipAddrFrom">
              <th mat-header-cell *matHeaderCellDef>IP Address</th>
              <td mat-cell *matCellDef="let element">
                {{ element.ipAddrFrom }}
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="orderIpCountry">
          <th mat-header-cell *matHeaderCellDef>
            Order IP <br />
            Country
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.orderIpCountry }}
          </td>
        </ng-container> -->

            <ng-container matColumnDef="paymentMethod">
              <th mat-header-cell *matHeaderCellDef>
                Payment <br />
                Method
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.paymentMethod }}
              </td>
            </ng-container>

            <ng-container matColumnDef="originalAmount">
              <th mat-header-cell *matHeaderCellDef>Order<br />Amount</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.originalAmount }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="originalAssetCurrencyCode">
              <th mat-header-cell *matHeaderCellDef>Order<br />Currency</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">
                  {{ element.originalAssetCurrencyCode }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="convertedRate">
              <th mat-header-cell *matHeaderCellDef>
                Currency <br />
                FX1
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.convertedRate }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Converted <br />Amount</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.amount }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="amountReceived">
              <th mat-header-cell *matHeaderCellDef>Amount<br />Received</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.amountReceived }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="installments">
              <th mat-header-cell *matHeaderCellDef>Installments</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.installments }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="currencyCode">
              <th mat-header-cell *matHeaderCellDef>Payment Currency</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.currencyCode }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="merchantFeeTotal">
              <th mat-header-cell *matHeaderCellDef>
                Merchant <br />
                Fees
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.merchantFeeTotal }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="merchantAmount">
              <th mat-header-cell *matHeaderCellDef>
                Value <br />
                Merchant<br />
                Amount
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.merchantAmount }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="personGatewayId">
              <th mat-header-cell *matHeaderCellDef>
                Customer<br />
                ID
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.personGatewayId }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="personEmail">
              <th mat-header-cell *matHeaderCellDef>
                Customer<br />
                Contact
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.personEmail }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="payoutStatus">
              <th mat-header-cell *matHeaderCellDef>Settlement<br />Status</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.payoutStatus }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="paymentCycleId">
              <th mat-header-cell *matHeaderCellDef>Statement</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.paymentCycleId }}</div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </app-card>
    </div>
  </ngx-simplebar>
  <div style="max-width: 70%">
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="pageSize"
      [length]="selector?.total"
      (page)="onPageChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
