<div [class]="theme" >
  <div id="sidebar" [ngClass]="{ minimized: isSidebarMinimized }">
    <div class="logo-container">
      <img
        [width]="getLogoWidth()"
        height="54px"
        [src]="getLogoSrc()"
        alt="Logo"
      />
      <button class="arrow-btn" (click)="toggleSidebar()">
        <img [src]="basePath + 'icons/arrow-btn.svg'" alt="Arrow Icon" />
      </button>
    </div>
    <div class="row ml-2 mr-2 mb-2">
      <hr class="divider" />
    </div>
    <div class="scrollable-part">
      <ng-container *ngFor="let item of menuItems">
        <ng-container *ngIf="item.isVisible !== false">
          <app-menu-item
            [item]="item"
            [isSidebarMinimized]="isSidebarMinimized"
            [expandedMenus]="expandedMenus"
            (toggleMenu)="toggleMenu($event)"
            (selectMenuItem)="selectMenuItem($event)"
            [selectedMenu]="selectedMenu"
          ></app-menu-item>
        </ng-container>
      </ng-container>
    </div>
    <div id="profile-menu">
      <hr class="divider" />
      <div class="row" style="margin-top: 0">
        <img
          class="image-circular mr-3"
          src="../../../assets/common/icons/avatar.svg"
        />
        <div
          class="white--text column column-no-margin"
          style="align-items: start"
        >
          <b style="margin-left: 0">
            {{ userData?.name }}
          </b>
          {{ userData?.username }}
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon style="color: #fff">arrow_drop_down</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="toggleCurrencyModal()">
      <mat-icon color="primary">paid</mat-icon>Change Currency
    </button>
    <button mat-menu-item (click)="togglePersonalInfoModal()">
      <mat-icon color="primary">badge</mat-icon>Personal Info
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon color="primary">logout</mat-icon>Logout
    </button>
  </mat-menu>
</div>

<app-modal
  title="Personal Info"
  [openModal]="personalInfoModal"
  (closeModalRequest)="togglePersonalInfoModal()"
  [minWidth]="'500px'"
>
  <app-profile />
</app-modal>

<app-modal
  [openModal]="currencyModal"
  (closeModalRequest)="toggleCurrencyModal()"
  [minWidth]="'500px'"
  title="Change Currency"
>
  <div class="ml-5 mr-5">
    <app-currency-modal (closeModalRequest)="toggleCurrencyModal()" />
  </div>
</app-modal>
