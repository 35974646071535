<div>
  <div class="page-container">
    <div class="row">
      <app-indices></app-indices>
    </div>
    <div class="row">
      <app-bar-chart></app-bar-chart>
    </div>
    <div class="row mt-4">
      <app-card [noTitle]="true" [lastDaysCard]="{ id: '30sales', amount: 0 }">
        <app-donut-chart
          [chartTitle]="'Top Payment <br> Systems'"
          [chartData]="topPaymentSystems.result"
        ></app-donut-chart>
      </app-card>

      <app-card [noTitle]="true" [lastDaysCard]="{ id: '30sales', amount: 0 }">
        <app-donut-chart
          [legendPosition]="'bottom'"
          [chartTitle]="'Top Contracts'"
          [chartData]="topContracts.result"
        ></app-donut-chart>
      </app-card>
    </div>
  </div>
</div>
