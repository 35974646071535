<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>Search:</mat-label>
      <input matInput [(ngModel)]="search" placeholder="Input your API Key " />
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="getApiKeysInfo()">
      Submit
    </button>
  </app-fieldset>

  <div class="row align-right">
    <button
      (click)="openModal()"
      style="min-width: 84px; height: 42px"
      class="send-btn ml-2 mb-5"
    >
      Create
    </button>
  </div>
  <div class="row">
    <app-card [cardTitle]="''">
      <table
        mat-table
        [dataSource]="dataSource"
        class="transactions-table"
        [loader]="isLoading"
      >
        <ng-container matColumnDef="api_key">
          <th mat-header-cell *matHeaderCellDef>API Key</th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            <div class="last-days-card">{{ element.api_key }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>Created At</th>
          <td mat-cell *matCellDef="let element">
            {{ element.created_at | date : "MM-dd-yyy hh:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="last_access">
          <th mat-header-cell *matHeaderCellDef>Last Used</th>
          <td mat-cell *matCellDef="let element">
            {{ element.last_access | date : "MM-dd-yyy hh:mm:ss" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </app-card>
  </div>
  <div class="row" style="width: 70%">
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="pageSize"
      [length]="selector"
      (page)="onPageChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<app-modal [openModal]="openCreateModal">
  <div class="row mt-3">
    <p>
      If you associate this key with a Contract ID, it will only work within
      that <br />
      contract. If you don't choose any contract, it will work with all
      contracts
    </p>
  </div>
  <div class="row">
    <button (click)="closeModal('create')" class="white-btn">Cancel</button>
    <button (click)="createAPIKey()" mat-flat-button color="primary">
      Create API Key
    </button>
  </div>
</app-modal>

<app-modal title="Create API Key" [openModal]="openSuccessModal">
  <div class="row">
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>API Key:</mat-label>
      <input matInput [value]="apiData.api_key" readonly />
      <button
        mat-icon-button
        matSuffix
        (click)="copyToClipboard(apiData.api_key)"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>API Secret:</mat-label>
      <input matInput [value]="apiData.api_secret" readonly />
      <button
        mat-icon-button
        matSuffix
        (click)="copyToClipboard(apiData.api_secret)"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="row">
    <p>
      After you close this screen, you won't be able to see the API Secret
      anymore!<br />
      Be sure you have it safely stored elsewhere
    </p>
  </div>
  <div class="row">
    <button (click)="closeModal('success')" class="white-btn">Close</button>
  </div>
</app-modal>
