<div [class]="theme">
  <div
    class="menu-item-container"
    [ngClass]="{
      selected: isMenuSelected(),
      expandable: item.children,
      expanded: isMenuExpanded()
    }"
    (click)="onMenuItemClick()"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    <img
      *ngIf="level === 1"
      class="mr-2"
      [src]="'./assets/common/icons/' + item.label + '-icon.svg'"
    />
    <a *ngIf="!item.children" [routerLink]="item.link">{{ item.label }}</a>
    <a *ngIf="item.children">{{ item.label }}</a>

    <mat-icon
      *ngIf="item.children"
      (click)="onToggleMenu(); $event.stopPropagation()"
    >
      {{ isMenuExpanded() ? 'expand_more' : 'chevron_right' }}
    </mat-icon>

    <div
      *ngIf="item.children && isSidebarMinimized && isHovering"
      class="flyout-submenu level-{{ level }}"
      (mouseenter)="onMouseEnter()"
      (mouseleave)="onMouseLeave()"
    >
      <ng-container *ngFor="let child of item.children">
        <app-menu-item
          [item]="child"
          [isSidebarMinimized]="isSidebarMinimized"
          [expandedMenus]="expandedMenus"
          (toggleMenu)="toggleMenu.emit($event)"
          (selectMenuItem)="selectMenuItem.emit($event)"
          [selectedMenu]="selectedMenu"
          [level]="level + 1"
        ></app-menu-item>
      </ng-container>
    </div>
  </div>

 <div
  *ngIf="item.children"
  class="submenu-container"
  [ngClass]="{
    expanded: isMenuExpanded(),
    'level-1': level === 1,
    'level-2': level === 2,
    'level-3': level === 3
  }"
>
  <ng-container *ngFor="let child of item.children">
    <app-menu-item
      [item]="child"
      [isSidebarMinimized]="isSidebarMinimized"
      [expandedMenus]="expandedMenus"
      (toggleMenu)="toggleMenu.emit($event)"
      (selectMenuItem)="selectMenuItem.emit($event)"
      [selectedMenu]="selectedMenu"
      [level]="level + 1"
    ></app-menu-item>
  </ng-container>
</div>
</div>
