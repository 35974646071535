import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  private baseUrl = `${environment.apiURL}/merchant`;

  constructor(private http: HttpClient) {}

  public getContracts() {
    const url = ` ${this.baseUrl}/contracts/list/`;

    return this.http.get<any>(url);
  }
}
