import { Component, OnInit } from '@angular/core';

import { ContractsService } from '../../../services/projects/contracts.service';
import { Contract } from 'src/app/models/contract.model';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  public data: any = {
    numbers: 455,
    description: 'Valids',
    fluctuation: 0.9,
  };

  public rows: any = [];
  public projectsList: Contract[] = [];
  public displayedColumns: string[] = [
    'method',
    'type',
    'fixed_value',
    'percent_value',
    'logic',
    'active',
    'startDate',
    'endDate',
    'country',
  ];
  public dataSource: Array<any> = [];
  public selectedProject: Contract = {
    active: true,
    createdAt: '',
    id: '',
    methods: [],
    name: '',
  };
  public paymentMethodsBrasil: any = [];
  public paymentMethodsMexico: any = [];

  constructor(private contractsService: ContractsService) {}

  ngOnInit() {
    this.contractsService.getContracts().subscribe(({ contracts }) => {
      this.projectsList = contracts.filter(
        (contract: any) => contract.contractType === 'PAYIN'
      );

      contracts.forEach((element: any) => {
        element.methods.forEach((method: any) => {
          method.fees.forEach((fee: any) => {
            if (!this.dataSource.some((e) => this.isFeeEqual(e, fee))) {
              this.dataSource.push(fee);
            }
          });
        });
      });
      this.selectedProject = this.projectsList[0];
      this.dataSource.sort((a, b) => a.method.localeCompare(b.method));
    });

    this.checkPaymentMethods();
  }

  private isFeeEqual(fee1: any, fee2: any): boolean {
    return (
      fee1.method === fee2.method &&
      fee1.type === fee2.type &&
      fee1.value === fee2.value
    );
  }

  public checkPaymentMethods() {
    this.paymentMethodsBrasil = [];
    this.paymentMethodsMexico = [];

    if (
      this.selectedProject.methods &&
      Array.isArray(this.selectedProject.methods)
    ) {
      for (let method of this.selectedProject.methods) {
        if (method.active && method.country === 'Brasil') {
          this.paymentMethodsBrasil.push(method);
        } else if (method.active && method.country === 'Mexico') {
          this.paymentMethodsMexico.push(method);
        }
      }
    }
  }
}
