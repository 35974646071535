import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  public isMinimized = false;

  constructor() { }

  toggleMinimization(): void {
    this.isMinimized = !this.isMinimized;
  }

}
