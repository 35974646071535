import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth/auth-service.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  public theme: string = environment.theme;
  public userData: any = {};

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.getUserList().subscribe((response) => {
      this.userData = response;
    });
  }

}
