import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentsService } from '../../services/payments/payments.service';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss'],
})
export class TransactionDetailComponent {
  public generalInfoList: any = [
    { label: 'Transaction Status', value: 'transaction_status' },
    { label: 'Additional Info', value: 'additional_info' },
    { label: 'Transaction ID', value: 'transaction_id' },
    { label: 'External ID', value: 'external_id' },
    { label: 'Contract ID', value: 'project_id' },
  ];
  public customerInfoList: any = [
    { label: 'Customer ID', value: 'customer_id' },
    { label: 'Customer Contact', value: 'customer_contact' },
    { label: 'Tax ID', value: 'tax_id' },
    { label: 'Order IP', value: 'order_ip' },
    { label: 'Order IP Country', value: 'order_ip_country' },
  ];
  public paymentInfoList: any = [
    { label: 'Order Method', value: 'order_method' },
    { label: 'Order Method Country', value: 'order_method_country' },
    { label: 'Card Number', value: 'card_number' },
    { label: 'Card Scheme', value: 'card_scheme' },
    { label: 'Card Holder', value: 'card_holder' },
    { label: 'Order Amount', value: 'order_amount' },
    { label: 'Refunded Amount', value: 'refunded_amount' },

    { label: 'Currency FX1', value: 'currency_fx' },
    { label: 'Value Total', value: 'customer_amount' },
  ];
  public otherInfoList: any = [{ label: 'Dispute ID', value: '' }];
  public transactionInfo: any = {
    general_info: {},
    payment_info: { fx_updated_date: null },
    customer_info: {},
    other_info: {},
    transaction_history: {},
  };
  public transactionId: string | null = null;

  public displayedColumns: string[] = ['status', 'timestamp'];
  public rows: any = [];
  public loading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private paymentsService: PaymentsService,
    private router: Router
  ) {
    this.loading = true;
    this.activatedRoute.queryParamMap.subscribe((queryParam) => {
      this.transactionId = queryParam.get('id');
      if (this.transactionId) {
        this.getTransactionInfo(this.transactionId);
      }
      this.loading = false;
    });
  }

  public redirectToRefund() {
    this.router.navigate(['payments/refund'], {
      queryParams: { id: this.transactionId },
    });
  }

  public exportLog() {
    if (this.transactionId) {
      this.paymentsService
        .downloadTransactionDetailLog(this.transactionId)
        .subscribe(
          (csvData: string) => {
            const blob = new Blob([csvData], { type: 'text/csv' });

            saveAs(blob, 'data.csv');
          },
          (error) => {
            console.error('Error downloading CSV:', error);
            alert('Error downloading CSV file');
          }
        );
    }
  }

  private getTransactionInfo(id: string) {
    this.paymentsService.getPaymentDetail(id).subscribe((response) => {
      this.transactionInfo = response;
    });
  }
}
