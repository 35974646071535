<div class="mt-5">
  <div class="row" style="width: 25%">
    <mat-form-field appearance="outline">
      <mat-label>Contract List</mat-label>
      <mat-select
        [(value)]="selectedProject"
        (selectionChange)="checkPaymentMethods()"
      >
        <mat-option *ngFor="let project of projectsList" [value]="project">
          {{ project.id }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row" *ngIf="selectedProject.id">
    <app-card cardTitle="General Settings" [hasDivider]="true">
      <p><b>Contract: </b> {{ selectedProject.name }}</p>
      <div class="row">
        <b>Status: </b>
        <p><app-status-card [status]="selectedProject.active" /></p>
      </div>
      <p><b>ID: </b> {{ selectedProject.id }}</p>
      <!-- <p><b>Statement Days: </b> Epag</p> -->
    </app-card>
    <app-card
      *ngIf="paymentMethodsBrasil"
      cardTitle="Payment Methods Brazil"
      [hasDivider]="true"
    >
      <div class="row" *ngFor="let method of paymentMethodsBrasil">
        <b class="flex-one">{{ method.name }}: </b>
        <app-status-card class="flex-one" [status]="method.active" />
      </div>
    </app-card>

    <app-card
      *ngIf="paymentMethodsMexico"
      cardTitle="Payment Methods Mexico"
      [hasDivider]="true"
    >
      <div class="row" *ngFor="let method of paymentMethodsMexico">
        <b class="flex-one">{{ method.name }}: </b>
        <app-status-card class="flex-one" [status]="method.active" />
      </div>
    </app-card>
  </div>

  <div class="row">
    <div class="mt-5">
      <div class="row">
        <app-card [cardTitle]="''">
          <table mat-table [dataSource]="dataSource" class="transactions-table">
            <ng-container matColumnDef="collectionCycle">
              <th mat-header-cell *matHeaderCellDef>Collection Cycle</th>
              <td mat-cell *matCellDef="let element">
                <div class="last-days-card">{{ element.collectionCycle }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="paymentId">
              <th mat-header-cell *matHeaderCellDef>Method</th>
              <td mat-cell *matCellDef="let element">
                {{ element.paymentId }}
              </td>
            </ng-container>

            <ng-container matColumnDef="merchantFromId">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element">
                {{ element.merchantFromId }}
              </td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef>Value</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdAt | date : "MM-dd-yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="paymentMethod">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                {{ element.paymentMethod }}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Activation</th>
              <td mat-cell *matCellDef="let element">
                {{ element.amount }}
              </td>
            </ng-container>

            <ng-container matColumnDef="currencyCode">
              <th mat-header-cell *matHeaderCellDef>Expiration</th>
              <td mat-cell *matCellDef="let element">
                {{ element.currencyCode }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cancellationDate">
              <th mat-header-cell *matHeaderCellDef>Territory</th>
              <td mat-cell *matCellDef="let element">
                {{ element.cancellationDate | date : "MM-dd-yyyy" }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </app-card>
      </div>
    </div>
  </div>
</div>
