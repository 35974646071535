<div class="row" style="align-items: center; justify-content: center">
  <img
    class="image-circular-big"
    [src]="
      theme === 'skin-epag'
        ? '../../../assets/common/icons/avatar-epag.svg'
        : '../../../assets/common/icons/avatar-letpay.svg'
    "
  />
</div>
<div class="row" *ngIf="userData">
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput disabled [(ngModel)]="userData.name" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput disabled [(ngModel)]="userData.email" />
  </mat-form-field>
</div>
