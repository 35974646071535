<div class="mt-5">
  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>Contract List</mat-label>
      <mat-select
        [(value)]="selectedProject"
        (selectionChange)="checkPaymentMethods()"
      >
        <mat-option *ngFor="let project of projectsList" [value]="project">
          {{ project.id }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="selectedProject.id">
    <div class="row align-items-start">
      <app-card cardTitle="General Settings" [hasDivider]="true">
        <div class="row">
          <b class="flex-one">Project: </b>
          <p class="flex-one">{{ selectedProject.name }}</p>
        </div>

        <div class="row">
          <b class="flex-one">Status: </b>
          <app-status-card class="flex-one" [status]="selectedProject.active" />
        </div>

        <div class="row">
          <b class="flex-one">ID: </b>
          <p class="flex-one">{{ selectedProject.id }}</p>
        </div>
        <div class="row">
          <b class="flex-one">Statement Days: </b>
          <p class="flex-one">{{ selectedProject.createdAt }}</p>
        </div>
      </app-card>

      <app-card
        *ngIf="paymentMethodsBrasil"
        cardTitle="Payment Methods Brazil"
        [hasDivider]="true"
      >
        <div class="row" *ngFor="let method of paymentMethodsBrasil">
          <b class="flex-one">{{ method.name }}: </b>
          <app-status-card class="flex-one" [status]="method.active" />
        </div>
      </app-card>

      <app-card
        *ngIf="paymentMethodsMexico"
        cardTitle="Payment Methods Mexico"
        [hasDivider]="true"
      >
        <div class="row" *ngFor="let method of paymentMethodsMexico">
          <b class="flex-one">{{ method.name }}: </b>
          <app-status-card class="flex-one" [status]="method.active" />
        </div>
      </app-card>
    </div>

    <div class="row">
      <div class="mt-5">
        <div class="row">
          <app-card [cardTitle]="'Custom Fee'" [hasDivider]="true">
            <table
              mat-table
              [dataSource]="dataSource"
              class="transactions-table mt-5"
            >
              <ng-container matColumnDef="method">
                <th mat-header-cell *matHeaderCellDef>Method</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.method }}
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.type }}
                </td>
              </ng-container>

              <ng-container matColumnDef="fixed_value">
                <th mat-header-cell *matHeaderCellDef>Fixed Value</th>
                <td mat-cell *matCellDef="let element">
                  {{ (element.amount | formatCurrency) || "-" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="logic">
                <th mat-header-cell *matHeaderCellDef>Logic</th>
                <td mat-cell *matCellDef="let element">
                  {{
                    element.logic === "SUM"
                      ? "Apply the sum of the values"
                      : "Apply the Highest Value"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="percent_value">
                <th mat-header-cell *matHeaderCellDef>Percent Value</th>
                <td mat-cell *matCellDef="let element">
                  {{
                    element.percentage === 0 ? "-" : element.percentage + "%"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  <img
                    [src]="
                      element.active
                        ? '../../../../assets/common/icons/enabled.svg'
                        : '../../../../assets/common/icons/disabled.svg'
                    "
                    alt=""
                  />
                </td>
              </ng-container>

              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Activation</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.startDate | date : "MM-dd-yyyy" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>Expiration</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.endDate | date : "MM-dd-yyyy" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef>Territory</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.country }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </app-card>
        </div>
      </div>
    </div>
  </div>
</div>
