<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field appearance="outline">
      <mat-label>Search:</mat-label>
      <input matInput [(ngModel)]="search" />
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="filterTable()">
      Filter
    </button>
  </app-fieldset>
  <div>
    <app-fieldset tittle="Actions">
      <button (click)="openModal()" mat-flat-button color="primary">
        New Block
      </button>
    </app-fieldset>
  </div>

  <div class="row">
    <app-card [cardTitle]="''">
      <table
        mat-table
        [dataSource]="dataSource"
        class="transactions-table"
        [loader]="isLoading"
      >
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">
            <div class="last-days-card">{{ element.access_rule_type }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="rule_type">
          <th mat-header-cell *matHeaderCellDef>Field</th>
          <td mat-cell *matCellDef="let element">
            {{ element.rule_type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="rule">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let element">
            {{ element.rule }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>
            Payment <br />
            Method
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date : "MM-dd-yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>Created At</th>
          <td mat-cell *matCellDef="let element">
            {{ element.created_at }}
          </td>
        </ng-container>

        <ng-container matColumnDef="blocked_by">
          <th mat-header-cell *matHeaderCellDef>Created By</th>
          <td mat-cell *matCellDef="let element">
            {{ element.blocked_by }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unblock">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.can_edit; else requestToUnblock">
              <button
                *ngIf="element.enabled; else newBlock"
                (click)="openUnblockModal(element)"
                class="send-btn ml-5"
              >
                Unblock
              </button>
              <ng-template #newBlock>
                <button
                  (click)="openReblockModal(element)"
                  class="block-btn ml-5"
                >
                  Block
                </button>
              </ng-template>
            </div>

            <ng-template #requestToUnblock>
              <div>Contact the Admin to unblock</div>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </app-card>
  </div>
  <div class="row" style="width: 70%">
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="pageSize"
      [length]="selector"
      (page)="onPageChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<app-modal
  [openModal]="openModalValue"
  (closeModalRequest)="handleCloseModal()"
  [minWidth]="'500px'"
  title="New Block"
>
  <app-block-modal
    [blockData]="selectedBlockRule"
    (closeModalRequest)="handleCloseModal()"
  ></app-block-modal>
</app-modal>

<app-modal
  [openModal]="openUnblockModalValue"
  [minWidth]="'500px'"
  title="Unblock"
  (closeModalRequest)="handleCloseModal()"
>
  <app-unblock-modal
    [blockedContract]="selectedBlockRule"
    (closeModalRequest)="handleCloseModal()"
  ></app-unblock-modal>
</app-modal>

<app-modal
  [openModal]="openReblockModalValue"
  [minWidth]="'500px'"
  (closeModalRequest)="handleCloseModal()"
>
  <app-reblock-modal
    (closeModalRequest)="handleCloseModal()"
    [blockData]="selectedBlockRule"
  ></app-reblock-modal>
</app-modal>
